<template>
  <MainLayout>
    <section class="landing-page ">
      <div class="Main-frame" style="height: auto;">
        <div class=" container">
          <h1 class="mt-4">Проверка организаций</h1>
          <h5 class="mt-lg-2 mt-3">Найдите контрагента и получите отчёт с актуальной информацией и факторами риска. <br>
            Отчёт доступен на любом устройстве или в формате-PDF</h5>
          <div class="container d-flex justify-content-center align-items-center">
            <div class="col-12 col-lg-9">
              <SearchForLandingPageComponent class="mt-5"></SearchForLandingPageComponent>

            </div>
          </div>
          <div class="mt-lg-1 mt-3 d-flex justify-content-center">
            <img src="../assets/images/landingPageFakturaOrg.png" width="90%">
          </div>
        </div>
      </div>
      <div class="container d-flex justify-content-center">
        <div class="wrapper">
          <div class="d-flex align-items-center justify-content-between flex-wrap">
            <div class="d-flex flex-column align-items-center px-2 pt-2 col-md col-sm-4  col-6 col-s-12">
              <img src="../assets/images/langdingPageImages/rateOfCounterparties.png">
              <p class="text-wrap text-center mt-2">Оценка <br> контрагентов</p>
            </div>
            <!-- <div class="d-flex flex-column align-items-center px-2 pt-2 col-md col-sm-4  col-6 col-s-12">
              <img src="../assets/images/langdingPageImages/rateOfCounterparties2.png">
              <p class="text-wrap text-center mt-2">Оценка <br> контрагентов</p>
            </div> -->
            <div class="d-flex flex-column align-items-center px-2 pt-2 col-md col-sm-4  col-6 col-s-12">
              <img src="../assets/images/langdingPageImages/lawsuitsAndDebts.png">
              <p class="text-wrap text-center mt-2">Судебные <br> процессы и долги</p>
            </div>
            <div class="d-flex flex-column align-items-center px-2 pt-2 col-md col-sm-4  col-6 col-s-12">
              <img src="../assets/images/langdingPageImages/risksReliability.png">
              <p class="text-wrap text-center mt-2">Риск <br> и благонадёжность</p>
            </div>
            <div class="d-flex flex-column align-items-center px-2 pt-2 col-md col-sm-4  col-6 col-s-12">
              <img src="../assets/images/langdingPageImages/relevanceOfData.png">
              <p class="text-wrap text-center mt-2">Актуальность <br> даннных</p>
            </div>
            <div class="d-flex flex-column align-items-center px-2 pt-2 col-md col-sm-4  col-6 col-s-12">
              <img src="../assets/images/langdingPageImages/governmentProcurement.png">
              <p class="text-wrap text-center mt-2">Госзакупки <br> &nbsp;</p>
            </div>
          </div>
        </div>
      </div>
      <!-- <div style="background-color: #F5F5F5;">
        <div class="container d-flex justify-content-center">
          <div class="wrapper">
            <div class="d-flex align-items-center justify-content-between flex-wrap">
              <div class="d-flex flex-column align-items-center px-2 pt-2 col-md col-sm-4  col-6 col-s-12">
                <p class="prasent-text">24,6%</p>
                <p class="text-wrap text-center mt-3">ЮЛ и ИП имеют факторы <br> риска <br>&nbsp;</p>
              </div>
              <div class="d-flex flex-column align-items-center px-2 pt-2 col-md col-sm-4  col-6 col-s-12">
                <p class="prasent-text">19,8%</p>
                <p class="text-wrap text-center mt-3">Выступают ответчиками <br> в судебных делах <br>&nbsp;</p>
              </div>
              <div class="d-flex flex-column align-items-center px-2 pt-2 col-md col-sm-4  col-6 col-s-12">
                <p class="prasent-text">4,9%</p>
                <p class="text-wrap text-center mt-3">Имеют задолженности <br> по исполнительным <br> производствам</p>
              </div>
              <div class="d-flex flex-column align-items-center px-2 pt-2 col-md col-sm-4  col-6 col-s-12">
                <p class="prasent-text">7,3%</p>
                <p class="text-wrap text-center mt-3">Работают с нарушениями <br> по результатам проверок
                  <br>Генпрокуратуры
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="container d-flex justify-content-center">
        <div class="wrapper d-lg-flex mt-2 mb-2 pb-4 pb-lg-0">
          <div class="d-lg-none col-12 d-flex px-4 flex-column justify-content-center align-items-center">
            <img src="../assets/images/langdingPageImages/generalInformation.png" alt="" style="width: 120%;">
          </div>
          <div class="col-12 col-lg-6 d-flex px-lg-4 text-center text-lg-start flex-column justify-content-center">
            <h2>Реквизиты и общие сведения</h2>
            <p>Наша система проверки контрагентов предоставляет вам инструмент для быстрого и эффективного поиска
              организаций.
            </p>
            <p class="mt-2">
              Теперь вы можете легко найти нужную компанию по её названию, реквизитам, региону или даже имени
              руководителя.
            </p>
          </div>
          <div class="d-none d-lg-block col-6 d-flex px-4 flex-column justify-content-center align-items-center">
            <img src="../assets/images/langdingPageImages/generalInformation.png" alt="" style="width: 120%;">
          </div>
        </div>
      </div>
      <div style="background-color: #F5F5F5;">
        <div class="container d-flex justify-content-center">
          <div class="wrapper d-lg-flex mt-4 mb-4">
            <div class="col-12 col-lg-6 d-flex px-0 px-lg-4  flex-column justify-content-center align-items-center">
              <img src="../assets/images/langdingPageImages/statistics.png" alt="" style="width: 90%;">
            </div>
            <div
              class="col-12 col-lg-6 d-flex px-0 px-lg-4  py-3 py-lg-0 flex-column justify-content-center text-center text-lg-start">
              <h2>Мгновенный отчёт <br> по рискам и благонадежности</h2>
              <p>Узнайте всё о руководителях, деловой репутации, судебных делах, задолженностях, блокировках счетов или
                банкротстве организации.
              </p>
            </div>

          </div>
        </div>
      </div>
      <div class="container d-flex justify-content-center">
        <div class="wrapper d-lg-flex mb-5">
          <div class="d-lg-none col-12  d-flex px-4 flex-column justify-content-center align-items-center mb-4">
            <img src="../assets/images/langdingPageImages/tenders.png" alt="" style="width: 110%;">
          </div>
          <div class="col-12 col-lg-6 d-flex px-0 px-lg-4 flex-column justify-content-center text-center text-lg-start">
            <h2>Данные о госзакупках</h2>
            <p>Определите конкурентов по тендеру и изучите их опыт в госзакупках, а также условия участия.
            </p>
            <p class="mt-2">
              Проверьте наличие в реестре недобросовестных поставщиков, оцените опыт исполнения госконтрактов и
              идентифицируйте признаки сговора в прошлых закупках
            </p>
          </div>
          <div class="d-none d-lg-block col-6 d-flex px-4 flex-column justify-content-center align-items-center mb-4">
            <img src="../assets/images/langdingPageImages/tenders.png" alt="" style="width: 110%;">
          </div>
        </div>
      </div>
      <div style="background-color: #F5F5F5;">
        <div class="container-xl d-flex justify-content-center ">
          <div class="wrapper">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="col-lg-6 px-4 col-12">
                <h5 class=" mb-4">Топ организаций по объему уставного капитала</h5>

                <p class="d-flex justify-content-between text-nowrap"
                  v-for="item in getTopAuthorizedFundCompanies()" :key="item">
                <p class="text-blue  text-truncate" style="cursor: pointer;" @click="redirectToCompany(item.inn)">{{
                    item.top }}. {{ item.name }}
                </p> <span class="text-green ">{{ numToString(item.fund) }} сум </span></p>
                <!-- <p class=" d-flex justify-content-between"><p class="text-blue text-truncate ">2. ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТ ....
                </p> <span class="text-green text-nowrap"> 11 500 643 550 сум </span></p>
                <p class=" d-flex justify-content-between"><p class="text-blue text-truncate ">3. ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТ ....
                </p> <span class="text-green text-nowrap"> 6 500 643 550 сум </span> </p>
                <p class=" d-flex justify-content-between"><p class="text-blue text-truncate">4. ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТ ....
                </p> <span class="text-green text-nowrap"> 120 643 550 сум </span> </p>
                <p class=" d-flex justify-content-between"><p class="text-blue text-truncate">5. ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТ ....
                </p> <span class="text-green text-nowrap"> 66 643 550 сум </span> </p> -->
              </div>
              <div class=" col-lg-6 px-4 col-12 mt-3 mt-lg-0">
                <h5 class=" mb-4">Топ организаций по количеству товарных знаков</h5>
                <p class="d-flex justify-content-between" v-for="item in getTopTradeMarksCompanies()" :key="item"><p class="text-blue text-truncate" style="cursor: pointer;" @click="redirectToCompany(item.inn)">{{item.top}}. {{item.name}}
                </p> <span class="text-green text-nowrap">{{item.count}}</span></p>
              </div>
              <!-- <div class="mt-5 mt-lg-0  col-lg-5 px-2 col-12">
                <h5 class="mb-4">Топ организаций по количеству филиалов</h5>
                <p class="d-flex justify-content-between"><p class="text-blue text-truncate">1. ОБЩЕСТВО С
                    ОГРАНИЧЕННОЙ ОТВЕТ ....
                </p> <span class="text-green text-nowrap">697 сум </span></p>
                <p class="d-flex justify-content-between"><p class="text-blue text-truncate">2. ОБЩЕСТВО С
                    ОГРАНИЧЕННОЙ ОТВЕТ ....
                </p> <span class="text-green text-nowrap"> 550 сум </span></p>
                <p class="d-flex justify-content-between"><p class="text-blue text-truncate">3. ОБЩЕСТВО С
                    ОГРАНИЧЕННОЙ ОТВЕТ ....
                </p> <span class="text-green text-nowrap"> 345 сум </span> </p>
                <p class="d-flex justify-content-between"><p class="text-blue text-truncate">4. ОБЩЕСТВО С
                    ОГРАНИЧЕННОЙ ОТВЕТ ....
                </p> <span class="text-green text-nowrap"> 241 сум </span> </p>
                <p class="d-flex justify-content-between"><p class="text-blue text-truncate">5. ОБЩЕСТВО С
                    ОГРАНИЧЕННОЙ ОТВЕТ ....
                </p> <span class="text-green text-nowrap"> 111 сум </span> </p>
              </div> -->
            </div>
            <!-- <div class="d-flex justify-content-between  flex-wrap">
              <div class="mt-5 col-lg-6 px-2 col-12">
                <h5 class=" mb-4">Топ организаций по количеству товарных знаков</h5>
                <p class="d-flex justify-content-between" v-for="item in getTopTradeMarksCompanies()" :key="item"><p class="text-blue text-truncate" style="cursor: pointer;" @click="redirectToCompany(item.inn)">{{item.top}}. {{item.name}}
                </p> <span class="text-green text-nowrap">{{item.count}}</span></p>
              </div>
              <div class="mt-5 col-lg-5 px-2 col-12">
                <h5 class=" mb-4">Топ организаций по количеству лицензий</h5>
                <p class="d-flex justify-content-between"><p class="text-blue text-truncate">1. ОБЩЕСТВО С
                    ОГРАНИЧЕННОЙ ОТВЕТ ....
                </p> <span class="text-green text-nowrap">697 сум </span></p>
                <p class="d-flex justify-content-between"><p class="text-blue text-truncate">2. ОБЩЕСТВО С
                    ОГРАНИЧЕННОЙ ОТВЕТ ....
                </p> <span class="text-green text-nowrap"> 550 сум </span></p>
                <p class="d-flex justify-content-between"><p class="text-blue text-truncate">3. ОБЩЕСТВО С
                    ОГРАНИЧЕННОЙ ОТВЕТ ....
                </p> <span class="text-green text-nowrap"> 345 сум </span> </p>
                <p class="d-flex justify-content-between"><p class="text-blue text-truncate">4. ОБЩЕСТВО С
                    ОГРАНИЧЕННОЙ ОТВЕТ ....
                </p> <span class="text-green text-nowrap"> 241 сум </span> </p>
                <p class="d-flex justify-content-between"><p class="text-blue text-truncate">5. ОБЩЕСТВО С
                    ОГРАНИЧЕННОЙ ОТВЕТ ....
                </p> <span class="text-green text-nowrap"> 111 сум </span> </p>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="container d-flex justify-content-center">
        <div class="wrapper">
          <h2 class="text-center pb-3">Источники данных</h2>
          <div class="mt-5 d-flex flex-wrap justify-content-xxl-between justify-content-center">
            <div class="card d-flex flex-column align-items-center mb-3 my-lg-5 my-xxl-0 p-4 col-12 col-lg mx-3"
              style="border-radius: 25px;">
              <div class="card " style="background-color: #F1F1F1; margin-top: -60px; padding: 15px;">
                <img src="../assets/images/langdingPageImages/stat.uz.png" width="40">
              </div>
              <p class="mt-3 text-center">Агентство статистики при Президенте Республики Узбекистан</p>
            </div>
            <div class="card d-flex flex-column align-items-center  my-5 my-xxl-0  p-4  col-12 col-lg mx-3" style="border-radius: 25px;">
              <div class="card " style="background-color: #F1F1F1; margin-top: -60px; padding: 15px;">
                <img src="../assets/images/langdingPageImages/gerb.png" width="40">
              </div>
              <p class="mt-3 text-center">Агентство государственных услуг при министерстве юстиции Республики Узбекистан</p>
            </div>
            <div class="card d-flex flex-column align-items-center my-3 mb-0 my-xxl-0 p-4  col-12 col-lg mx-3" style="border-radius: 25px; ">
              <div class="card " style="background-color: #F1F1F1; margin-top: -60px; padding: 15px;">
                <img src="../assets/images/langdingPageImages/ministr.png" width="40">
              </div>
              <p class="mt-3 text-center">Министерство юстиции Республики Узбекистан</p>
            </div>

          </div>
        </div>
      </div>
    </section>
  </MainLayout>
</template>

<script>
import MainLayout from '@/layouts/MainLayout.vue'
import SearchForLandingPageComponent from '@/components/SearchForLandingPageComponent.vue'
import { mapGetters, mapActions, mapMutations } from 'vuex';
export default {
  name: 'HomeView',
  components: {
    MainLayout,
    SearchForLandingPageComponent
  },
  methods: {
    ...mapGetters([
      'getTopTradeMarksCompanies',
      'getTopAuthorizedFundCompanies'
    ]),
    ...mapMutations([
      'setBreadcrumb',
      'resetTabsData'
    ]),
    ...mapActions([
      'topAuthorizedFundCompanies_api',
      'topTradeMarksCompanies_api'
    ]),
    numToString(number) {
      let dollarUS = Intl.NumberFormat("en-US",);
      let price = number;
      if (number != undefined) {
        price = number;
      }
      let str = dollarUS.format(price);

      return str.replaceAll(',', ' ');
    },
    redirectToCompany(inn) {
      window.scrollTo(0,0)
      this.$router.push({name:'company', params: {inn: inn}});
    }
  },
  async mounted() {
    let breadcrumb = [{
        id: 'home',
        img: 'home.png',
        name: '',
        url: '/'
      }]
    this.setBreadcrumb(breadcrumb);
    this.resetTabsData();
    await this.topAuthorizedFundCompanies_api();
    await this.topTradeMarksCompanies_api();
  }
}
</script>
