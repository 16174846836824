<template>
  <div v-show="isProcess" class="snippet" data-title="dot-spin">
    <div class="stage">
      <div class="p-4 d-flex justify-content-center align-items-end rounded"
        style="color: white;background-color: #8b8b8b;">
        <h2 class="text-nowrap m-0">Идет сбор данных

        </h2>
        <div class="loadings  pb-1">
          <span class="loading__dot"></span>
          <span class="loading__dot"></span>
          <span class="loading__dot"></span>
        </div>
        <!-- <div class="dot-spin mx-3"></div> -->
      </div>
    </div>
  </div>
  <router-view v-if="sendAuth" />
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import AuthService from './services/authService';
export default {
  data()  {
    return {
      sendAuth: true
    }
  },
  methods: {
    
    ...mapGetters([
      'getProcess'
    ]),
    ...mapMutations([
      'setAuth'
    ])
  },
  computed: {
    isProcess() {
      return this.getProcess();
    }
  },
  watch: {
    async '$route.fullPath'(to, from) {
      if (this.$route.query.code) {
        this.sendAuth = false;
        let result = await AuthService.getTokenWithFaktura(this.$route.query.code);
        if (result.data.success) {
          let responseData = result.data.data;
          if (responseData.isSuccess) {
            AuthService.saveToken(responseData.accessToken);
            AuthService.saveUserName(responseData.fullName);
            this.$router.replace({ query: {} });
          }
        }
        this.sendAuth = true;
      }
    }
  },
  
}
</script>


<style>
.loadings {
  display: flex;
  align-items: end;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.loading__dot {
  animation: dot ease-in-out 1s infinite;
  background-color: white;
  display: inline-block;
  height: 5px;
  margin: 3px;
  width: 5px;
  border-radius: 50px;
}

.loading__dot:nth-of-type(2) {
  animation-delay: 0.2s;
}

.loading__dot:nth-of-type(3) {
  animation-delay: 0.3s;
}

@keyframes dot {
  0% {
    background-color: white;
    transform: scale(1);
  }

  50% {
    background-color: blue;
    transform: scale(1.4);
  }

  100% {
    background-color: white;
    transform: scale(1);
  }
}
</style>